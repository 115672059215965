var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.contents
    ? _c(
        "ul",
        { staticClass: "dp-capsule-list" },
        _vm._l(_vm.contents, function (content) {
          return _c("DpCapsuleListItem", {
            key: content.index,
            attrs: { content: content },
            on: { "select-capsule": _vm.onSelect },
          })
        }),
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }