<template>
  <ul v-if="contents" class="dp-capsule-list">
    <DpCapsuleListItem
      v-for="content in contents"
      :key="content.index"
      :content="content"
      @select-capsule="onSelect" />
  </ul>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  components: {
    DpCapsuleListItem: () => import('@/components/dripPod/capsule/ListItem.vue')
  },

  props: {
    contents: {
      type: Array,
      default: null
    }
  },

  setup(props, context) {
    const onSelect = () => {
      context.emit('select-capsule');
    };

    window.localStorage.removeItem('BackFromProRecipeByCapsuleId');

    return {
      onSelect
    };
  }
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables';
@use '@/assets/scss/mixin';

.dp-capsule-list {
  display: grid;
  place-items: start;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 0 -4px;
}
</style>
